<template>
    <div>
        <f-data-table
            fixed-header
            :columns="quotaColumns"
            :items="filteredUserTotalStakeItems"
            :loading="loading || getWithdrawalLoading"
            force-loading
            class="f-data-table-body-bg-color"
            @action="action"
        >
            <template v-slot:after-table>
                <div v-if="showFTMVault" class="tooltip-table">
                    <f-info
                        show-on-hover
                        window-class="light"
                        window-style="max-width: 350px;"
                    >
                        In this block, you can stake your VC in order to get up
                        to 100% discount on gas for transactions.
                    </f-info>
                </div>
                <div class="after-table">
                    <div class="add-stake">
                        <button
                            class="btn-delegation"
                            @click="showStakeDialog = true"
                        >
                            <span>+Add Stake</span>
                        </button>
                    </div>
                </div>
            </template>
        </f-data-table>
        <StakeDialog
            v-if="showStakeDialog"
            :loading="getDialogLoading"
            :error-msg="getErrorMsg"
            @close="closeDialog"
            @action="stake"
        />
        <WithdrawDialog
            v-if="showWithdrawDialog"
            :amount="withdrawItem.amount"
            :loading="getDialogLoading"
            @close="closeDialog"
            @action="withdrawAction"
        />
    </div>
</template>

<script>
import FDataTable from "@/components/core/FDataTable/FDataTable.vue";
import appConfig from "../../app.config.js";
import FInfo from "@/components/core/FInfo/FInfo.vue";
import {
    formatNumberByLocale,
    formatDate,
    timeNow,
    days,
    hours,
    minutes,
    seconds,
    getTime
} from "@/filters.js";
import StakeDialog from "../components/dialogs/StakeQuotaDialog.vue";
import WithdrawDialog from "@/components/dialogs/Withdraw.vue";
import { mapGetters, mapActions } from "vuex";

export default {
    components: {
        FDataTable,
        FInfo,
        StakeDialog,
        WithdrawDialog
    },
    props: {
        items: { type: Array },
        loading: { type: Boolean }
    },
    data() {
        return {
            showFTMVault: appConfig.flags.ftmVault,
            showStakeDialog: false,
            showWithdrawDialog: false,
            intervalid2: null,
            amount: "",
            quotaColumns: [
                {
                    name: "stakingName",
                    label: "User wallet"
                    // itemProp: "delegation.createdTime"
                },
                {
                    name: "amount",
                    label: `Amount (VC)`,
                    cssClass: " align-center"
                    // itemProp: "delegation.amount",
                },
                {
                    name: "action",
                    label: "",
                    cssClass: " align-center"
                    // itemProp: "delegation.lockedUntil"
                }
            ],
            withdrawItem: {},
            requestItems: []
        };
    },
    watch: {
        getSuccessMsg(value) {
            if (value) {
                this.showStakeDialog = false;
                this.showWithdrawDialog = false;
            }
        },
        getErrorMsg(value) {
            if (value) {
                this.showStakeDialog = false;
                this.showWithdrawDialog = false;
            }
        },
        getStakeWithTime(length) {
            if (!length) {
                this.setRequestItems();
                this.getActiveWithdrawalRequestIDs();
                clearInterval(this.intervalid2);
            }
        },
        getWithdrawalRequestsLength() {
            this.setRequestItems();
        }
    },
    methods: {
        ...mapActions([
            "quotaStake",
            "updateProviderState",
            "quotaWithdrawStake",
            "getActiveWithdrawalRequestIDs"
        ]),
        getTimeToClaim(time) {
            const sec = this.getHoldTime - (timeNow() - time);
            const daysToClaim = days(sec);
            const hoursToClaim = hours(daysToClaim);
            const minutesToClaim = minutes(daysToClaim, hoursToClaim);
            const secondsToClaim = seconds(
                daysToClaim,
                hoursToClaim,
                minutesToClaim
            );

            return sec > 0
                ? `${getTime(hoursToClaim)}:${getTime(
                      minutesToClaim
                  )}:${getTime(secondsToClaim)}`
                : 0;
        },
        intervalRequest() {
            this.intervalid2 = setInterval(() => {
                this.setRequestItems();
            }, 1000);
        },
        setRequestItems() {
            if (this.getWithdrawalRequests.length) {
                this.requestItems = this.getWithdrawalRequests.map(r => ({
                    id: r.id,
                    amount: r.amount,
                    stakingName: this.shortAddress,
                    dateUnstaking: formatDate(
                        Number(r.unlockTime) * 1000,
                        false,
                        true
                    ),
                    timeToClaim: this.getTimeToClaim(r.unlockTime),
                    action: "Withdraw"
                }));
            } else this.requestItems = [];
        },
        action(item) {
            console.log(item, "action");
            if (item.action === "Withdraw") {
                this.showWithdrawDialog = true;
                this.withdrawItem = item;
            } else {
                this.$emit("unstake", item);
            }
        },
        closeDialog() {
            this.showStakeDialog = false;
            this.showWithdrawDialog = false;
        },
        stake(amount) {
            this.quotaStake({
                stake: amount
            });

            this.updateProviderState();
        },
        withdrawAction() {
            if (this.withdrawItem.id) {
                this.quotaWithdrawStake({ wrID: this.withdrawItem.id });
            }
        },
        formatNumberByLocale
    },
    mounted() {
        this.intervalRequest();
        this.setRequestItems();
    },
    unMounted() {
        clearInterval(this.intervalid2);
    },
    computed: {
        ...mapGetters([
            "balance",
            "validatorsIDs",
            "shortAddress",
            "getDialogLoading",
            "getErrorMsg",
            "getSuccessMsg",
            "getUserTotalStake",
            "getWithdrawalRequests",
            "getWithdrawalLoading",
            "getHoldTime"
        ]),
        userWithdrawalRequests() {
            return this.getWithdrawalRequests.map(r => ({
                id: r.id,
                stakingName: this.shortAddress,
                amount: r.amount,
                action: "claim"
            }));
        },
        userTotalStakeItem() {
            return [
                {
                    id: this.shortAddress,
                    stakingName: this.shortAddress,
                    amount: this.getUserTotalStake,
                    action: "unstake"
                }
            ];
        },
        filteredUserTotalStakeItem() {
            return this.userTotalStakeItem.filter(s => Number(s.amount));
        },
        filteredUserTotalStakeItems() {
            return [...this.filteredUserTotalStakeItem, ...this.requestItems];
        },
        stakeAmount() {
            return this.items
                .reduce((acc, curr) => (acc += +curr.amount), 0)
                .toFixed(2);
        },
        getStakeWithTime() {
            if (!this.requestItems.length) return false;
            return this.requestItems.filter(r => r.timeToClaim).length;
        },
        getWithdrawalRequestsLength() {
            return this.getWithdrawalRequests.length;
        }
    }
};
</script>
<style lang="scss">
a.btn-delegation:active,
a.btn-delegation:hover,
a.btn-delegation {
    text-decoration: none;
}

.btn-delegation {
    --btn-delegation-color: black;
    --btn-delegation-border: 1px solid black;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 194px;
    height: 50px;
    background-color: transparent;
    border: var(--btn-delegation-border);
    transition: 200ms;
    font-size: 18px;
    font-weight: 700;
    border-radius: 100px;
    span {
        color: var(--btn-delegation-color);
    }
    &:active {
        background-color: #3478f6;
        border: 1px solid #3478f6;
        span {
            color: white;
        }
    }
    @media (min-width: 768px) {
        &:hover {
            background-color: #3478f6;
            border: 1px solid #3478f6;
            span {
                color: white;
            }
        }
    }
}
.gas {
    color: #3478f6;
    font-size: 20px;
    font-weight: bold;
}

.after-table {
    width: 100%;
    margin-top: 24px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
    @media (min-width: 768px) {
        flex-direction: row;
    }
    .select-block {
        width: 100%;
        max-width: 298px;
        display: flex;
        flex-direction: column;
        gap: 12px;
        @media (min-width: 768px) {
            max-width: 211px;
        }
    }
    .cashback {
        display: flex;
        align-items: center;
        gap: 12px;
    }
    .tooltip-gas {
        display: flex;
        align-items: flex-start;
        padding-bottom: 16px;
    }
    .add-stake {
        padding-bottom: 16px;
        @media (min-width: 768px) {
            margin-left: auto;
        }
    }
}

.tooltip-table {
    position: absolute;
    right: 65px;
    top: 15px;
    z-index: 9;
}
@media (max-width: 1000px) {
    .tooltip-table {
        position: absolute;
        right: 40px;
        top: 15px;
        z-index: 9;
    }
}

@media (max-width: 800px) {
    .tooltip-table {
        position: absolute;
        right: 20px;
        top: 15px;
        z-index: 9;
    }
}

@media (max-width: 650px) {
    .tooltip-table {
        position: absolute;
        right: 0px;
        top: 15px;
        z-index: 9;
    }
}
</style>
